import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheService } from 'ionic-cache';

import { CONFIG } from '../../../config/config';

export type ApiParams = HttpParams | { [param: string]: string | string[] };

@Injectable()
export class ApiService {

    constructor(
        protected http: HttpClient,
        protected cache: CacheService,
    ) {
    }

    get(route: string, params?: ApiParams, ttl = 60 * 60): Observable<any> {
        const url = `${CONFIG.SERVER.API_ENDPOINT}/${route}`;
        const request = this.http.get(url, {params});

        if (!ttl) {
            return request;
        }

        const cacheKey = btoa(url + JSON.stringify(params));

        return this.cache.loadFromDelayedObservable(cacheKey, request, null, ttl, 'none');
    }

    post(route: string, body?: any, params?: ApiParams): Observable<any> {
        const url = `${CONFIG.SERVER.API_ENDPOINT}/${route}`;
        return this.http.post(url, body, {params});
    }

    put(route: string, body?: any, params?: ApiParams): Observable<any> {
        const url = `${CONFIG.SERVER.API_ENDPOINT}/${route}`;
        return this.http.put(url, body, {params});
    }

    delete(route: string, params?: ApiParams): Observable<any> {
        const url = `${CONFIG.SERVER.API_ENDPOINT}/${route}`;
        return this.http.delete(url, {params});
    }

}
