import { Injectable } from '@angular/core';

import { Client } from '../../models';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class ClientService {

    client: Client;

    constructor(
        protected api: ApiService,
    ) {
    }

    getClientById(clientId: string, forceRefresh = false): Observable<Client> {
        return new Observable<Client>(observer => {
            this.api.get(`client/${clientId}`, null, forceRefresh ? 0 : (60 * 60)).subscribe(client => {
                this.client = client;
                observer.next(this.client);
                observer.complete();
            }, error => {
                console.warn('Failed to get client.', error);
                observer.next(null);
                observer.complete();
            });
        });
    }

}
