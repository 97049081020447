import { NgModule } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage';
import { CacheModule } from 'ionic-cache';

import { ApiService, CheckInService, ClientService, UserService } from '.';

@NgModule({
    imports: [
        CacheModule.forRoot({keyPrefix: 'cr'}),
        IonicStorageModule.forRoot(),
    ],
    providers: [
        ApiService,
        CheckInService,
        ClientService,
        UserService,
    ],
})
export class ServicesModule {
}
