import { EventEmitter, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';

import { CheckIn } from '../../models';
import { ApiService } from './api.service';


@Injectable()
export class CheckInService {

    onCheckIn = new EventEmitter<CheckIn>();

    constructor(
        protected api: ApiService,
        protected storage: Storage,
    ) {
    }

    checkIn(checkIn: CheckIn): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.api.post('check-in', {
                clientId: checkIn.client.id,
                userId: checkIn.user.id,
                people: checkIn.people,
            }).subscribe(() => {
                observer.next(true);
                observer.complete();
                this.onCheckIn.emit(checkIn);
            }, error => {
                console.warn('Failed to check in!', error);
                observer.next(false);
                observer.complete();
            });
        });
    }
}
