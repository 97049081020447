import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'tabs',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsModule),
    },
    {
        path: 'check-in/:id',
        redirectTo: 'tabs/check-in/:id/people',
        pathMatch: 'full',
    },
    {
        path: 'people',
        loadChildren: () => import('./pages/people/people.module').then(m => m.PeopleModule),
    },
    {
        path: 'check-in',
        loadChildren: () => import('./pages/check-in/check-in.module').then(m => m.CheckInModule),
    },
    {
        path: 'information',
        loadChildren: () => import('./pages/information/information.module').then(m => m.InformationModule),
    },
    {
        path: '',
        redirectTo: 'tabs',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
