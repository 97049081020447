import { EventEmitter, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable } from 'rxjs';

import { Person, User } from '../../models';
import { ApiService } from './api.service';


@Injectable()
export class UserService {

    user: User;

    onUpdate = new EventEmitter<User>();

    constructor(
        protected api: ApiService,
        protected storage: Storage,
    ) {
    }

    getUser(forceRefresh = false): Observable<User> {
        return new Observable<User>(observer => {
            this.storage.get('user').then((storedUser: User | null) => {
                if (storedUser) {
                    this.user = storedUser;
                    this.api.get(`user/${this.user.id}`, null, forceRefresh ? 0 : (60 * 60)).subscribe(() => {
                        observer.next(this.user);
                        observer.complete();
                    }, error => {
                        console.log('User not found on server. Creating a new one.', error);
                        this.createUser().subscribe((newUser: User) => {
                            console.log('Created user because none found in storage', newUser);
                            this.user = newUser;
                            observer.next(this.user);
                            observer.complete();
                        });
                    });
                } else {
                    this.createUser().subscribe((newUser: User) => {
                        console.log('Created user because none found in storage', newUser);
                        this.user = newUser;
                        observer.next(this.user);
                        observer.complete();
                    });
                }
            });
        });
    }

    createUser(): Observable<User> {
        return new Observable<User>(observer => {
            this.api.post('user').subscribe(user => {
                this.user = user;
                this.saveUser().subscribe(() => {
                    observer.next(user);
                    observer.complete();
                });
            }, error => {
                console.warn('Failed to create user!', error);
                observer.next(null);
                observer.complete();
            });
        });
    }

    addPerson(person: Person): Observable<Person | boolean> {
        return new Observable<Person | boolean>(observer => {
            this.api.post('person', {
                userId: this.user.id,
                person,
            }).subscribe(createdPerson => {
                person.id = createdPerson.id;
                this.saveUser().subscribe(() => {
                    observer.next(person);
                    observer.complete();
                });
            }, error => {
                console.warn('Failed to add person', error);
                observer.next(false);
                observer.complete();
            });
        });
    }

    updatePerson(person: Person): Observable<Person | boolean> {
        return new Observable<Person | boolean>(observer => {
            this.api.put(`person/${person.id}`, {
                userId: this.user.id,
                person,
            }).subscribe(() => {
                this.saveUser().subscribe(() => {
                    observer.next(person);
                    observer.complete();
                });
            }, error => {
                console.warn('Failed to update person', error);
                observer.next(false);
            });
        });
    }

    deletePerson(person: Person): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.api.delete(`person/${person.id}`, {
                userId: this.user.id,
            }).subscribe(() => {
                this.saveUser().subscribe(() => {
                    observer.next(true);
                    observer.complete();
                });
            }, error => {
                console.warn('Failed to delete person', error);
                observer.next(false);
            });
        });
    }

    saveUser(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.storage.set('user', this.user).then(() => {
                observer.next(true);
                observer.complete();
                this.onUpdate.emit(this.user);
            }).catch(() => {
                observer.next(false);
                observer.complete();
            });
        });
    }

}
