import { ConfigInterface } from './config.interface';

export const CONFIG: ConfigInterface = {
    ENVIRONMENT: 'test',

    LANGUAGES: {
        en: 'English',
        nl: 'Nederlands',
    },

    SERVER: {
        API_ENDPOINT: 'https://bezoekersregistratie.pwstaging.tech/api',
    },

    SUPPORT_EMAIL: 'info@markr.eu',
};
