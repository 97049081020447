import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '../config/config';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    constructor(
        protected translate: TranslateService,
    ) {
        this.setDefaultLanguage();
    }

    setDefaultLanguage() {
        let lang = localStorage.getItem('lang') || navigator.language.split('-')[0];

        if (!lang || !CONFIG.LANGUAGES[lang]) {
            lang = Object.keys(CONFIG.LANGUAGES)[0];
        }

        this.translate.onDefaultLangChange.subscribe(change => {
            localStorage.setItem('lang', change.lang);
        });

        this.translate.setDefaultLang(lang);
    }

}
